import { defineComponent, ref, reactive } from '@vue/composition-api';
import { useFindHelpdeskStoriesQuery } from '@/generated-types/graphql.types';
import debounce from 'lodash/debounce';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
export default defineComponent({
    setup() {
        const searchQuery = ref('');
        const stories = ref([]);
        const findStoriesQueryVariables = reactive({
            searchTerm: ''
        });
        const findStoriesQueryOptions = reactive({
            enabled: false
        });
        const { onResult, refetch } = useFindHelpdeskStoriesQuery(findStoriesQueryVariables, findStoriesQueryOptions);
        onResult((result) => {
            if (!result?.data?.search_helpdesk_stories)
                return;
            stories.value =
                result.data.search_helpdesk_stories.stories.map((story) => {
                    const _story = JSON.parse(story.story);
                    return {
                        label: globalThis.$i18n.locale === 'nl'
                            ? _story.name
                            : _story.translated_slugs[0].name,
                        id: globalThis.$i18n.locale === 'nl'
                            ? _story.full_slug
                            : _story.translated_slugs[0].path
                    };
                }) || [];
        });
        const onSearchTermInput = (searchQuery) => {
            findStoriesQueryVariables.searchTerm = searchQuery;
            findStoriesQueryOptions.enabled = true;
            refetch(findStoriesQueryVariables);
        };
        const goToStory = (storySlug, __) => {
            const route = `${HelpdeskRoutes.Main}/${storySlug.replace('helpdesk/', '')}`;
            globalThis.$router.push(useGetLocalizedPath(route));
        };
        return {
            searchQuery,
            stories,
            goToStory,
            onSearch: debounce(onSearchTermInput, 500)
        };
    }
});
